.table-container,
.table-container-admin {
  table {
    width: 80%;
    margin: auto;
    padding: 10px;
  }

  .table-head {
    background-color: white;
    th {
      text-align: center;
    }
  }

  .table-body > tr:nth-child(even) {
    background-color: rgba(125, 142, 149, 0.9);
  }
  .table-row {
    background-color: rgba(125, 142, 149, 0.7);
    td {
      text-align: center;
      color: white;
    }
  }
}

@media (max-width: 880px) {
  .table-container {
    table {
      width: 300px;
      .table-head {
        tr {
          th {
            font-size: 20px;
          }
        }
      }
      .table-body {
        tr {
          td {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 655px) {
  .table-container {
    table {
      width: 300px;
      .table-head {
        display: none;
        tr {
          th {
            font-size: 16px;
          }
        }
      }
      .table-body {
        tr {
          td {
            font-size: 12px;
          }
        }
      }
    }
  }
  .table-container-admin {
    table {
      width: 350px;
      .table-head {
        display: none;
        tr {
          th {
            font-size: 16px;
          }
        }
      }
      .table-body {
        tr {
          td {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.button-container-table {
  display: flex;
  justify-content: center;
  gap: 10px;

  .btn-delete {
    background-color: crimson;
    border-radius: 35px;
  }
}

@media (max-width: 655px) {
  .button-container-table {
    display: flex;
    justify-content: center;
    gap: 10px;

    .btn-delete {
      background-color: crimson;
      border-radius: 35px;
      font-size: 6px;
      width: 60px;
    }
  }
}

@media (max-width: 900px) {
  .button-admin {
    span {
      font-size: 15px;
    }
  }
}

@media (max-width: 420px) {
  .table-container-admin {
    table {
      width: 320px;
      .table-head {
        display: none;
        tr {
          th {
            font-size: 16px;
          }
        }
      }
      .table-body {
        tr {
          td {
            font-size: 16px;
          }
        }
      }
    }
  }
  .button-admin {
    span {
      font-size: 10px;
    }
  }
}
