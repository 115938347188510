.acces-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .acces-content {
    border: 5px solid $color-5;
    border-radius: 35px;
    background-color: white;
    margin-top: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;

    align-items: center;

    h3 {
      text-align: center;
    }
    img {
      width: 70%;
      height: 70%;
      object-fit: cover;
      object-position: top;
      display: block;
      margin: 0 auto;
      border-radius: 50px;
      box-shadow: 0 8px 1px rgba(255, 255, 255, 0.1),
        0 16px 1px rgba(255, 255, 255, 0.05);
    }

    .container-media {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: space-between;
      margin-top: 15px;

      video {
        height: 450px;
        width: 600px;
      }

      audio {
        width: 80%;
        border-radius: 35px;
        background-color: $color-1;
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
        transform: scale(1.05);
      }
    }
  }
}
