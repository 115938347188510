nav {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 25px;
  background-color: $color-2;
  box-shadow: inset 0px -1px 0px $color-5;

  .nav-icon {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-right: 20px;

    span {
      display: none;
    }
  }

  .hamburger {
    display: none;
  }

  .navlinks-container {
    .navActive {
      border-bottom: 3px solid $color-3;
      span::after {
        display: none;
      }
    }
    span {
      margin: 0 10px;
      font-size: 20px;
      font-family: $font-2;
      color: $color-3;
      display: inline-block;
      position: relative;
    }
    span::after {
      content: "";
      display: block;
      position: relative;
      bottom: 7px;
      width: 100%;
      height: 1px;
      transform: scaleX(0);
      transform-origin: left;
      background-color: $color-3;
      transition: transform 0.3s ease-out;
    }
    span:hover::after {
      transform: scaleX(1);
    }
  }

  .nav-authentication {
    margin-left: auto;
    .sign-btns {
      button {
        font-family: $font-2;
        color: $color-1;
        font-size: 16px;
        margin: 0 5px;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        padding: 10px 5px;
      }
      .btn-signin {
        background-color: $color-5;
        border-radius: 15px;
        &:hover {
          background-color: $color-4;
        }
      }
      .btn-login {
        border-radius: 15px;
        background-color: $color-4;
        &:hover {
          background-color: $color-3;
          color: $color-1;
        }
        &:focus {
          background-color: $color-1;
        }
      }
      .btn-logout {
        background-color: $color-3;
        color: $color-1;
        border-radius: 15px;
        &:hover {
          background-color: darken($color-3, 10);
        }
      }
    }

    .user-sign {
      display: none;
    }
  }

  img {
    width: 60px;
    height: 50px;
  }
}

@media (max-width: 1158px) {
  nav {
    padding: 15px 20px;
    position: relative;

    .nav-icon {
      order: 2;
      margin: 0 auto;

      span {
        display: contents;
        font-family: $font-2;
        color: $color-4;
        font-size: 25px;
      }
    }

    .navlinks-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      background-color: $color-2;
      top: 100%;
      left: 0;
      height: max-content;
      padding: 15px 50px 15px 20px;
      border-right: 1px solid $color-5;
      transform: translate(-100%);
      transition: transform 0.3s ease-out;

      span {
        font-size: 16px;
        margin: 10px 0;
      }
      .navActive {
        border: none;
        :nth-child(1) {
          color: $color-4;
        }
      }
    }
    .openit {
      transform: translate(0%);
      z-index: 20;
    }
    .main-navlinks {
      order: 1;
    }

    .hamburger {
      width: 20px;
      height: 20px;
      cursor: pointer;
      border: none;
      display: flex;
      background: $color-2;
      align-items: center;
      position: relative;
      span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $color-4;
        position: absolute;
        pointer-events: none;
        transition: opacity 0.3s 0.15s ease-out;
      }

      :nth-child(1),
      :nth-child(3) {
        transition: transform 0.3s ease-out;
      }
      :nth-child(1) {
        transform: translateY(7px);
      }
      :nth-child(3) {
        transform: translateY(-7px);
      }
    }
    .open span:nth-child(1) {
      transform: translate(0) rotate(135deg);
    }
    .open span:nth-child(2) {
      opacity: 0;
      transition: opacity 0s ease-out;
    }
    .open span:nth-child(3) {
      transform: translate(0) rotate(-135deg);
    }
    .nav-authentication {
      order: 3;
      margin-left: 0;
      .sign-btns {
        display: none;
      }
      .user-sign {
        display: block;
        cursor: pointer;
        border: none;
        i {
          color: $color-3;
          font-size: 30px;
          padding-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  nav {
    .nav-icon {
      img {
        width: 60px;
        height: 50px;
      }
      span {
        display: none;
      }
    }
    .nav-authentication {
      .user-sign {
        i {
          font-size: 20px;
        }
      }
    }
  }
}
