.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 80%;
  margin: auto;
}
.contact-form {
  background-color: white;
  border-radius: 35px;
  width: 80%;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 10px 5px 5px $color-2;
  .label-form {
    font-size: 35px;
    color: $color-1;
    .formFieldError {
      color: crimson;
      border-color: crimson;
    }
    textarea {
      font-size: 15px;
      height: 250px;
    }
    .message-contact-form {
      font-size: 15px;
      height: 180px;
      border-radius: 10px;
      padding: 10px;
    }
    #resume {
      height: 150px;
    }

    .checkbox-input {
      span {
        font-size: 35px;
        color: $color-4;
      }
    }
  }

  .required-info {
    font-style: italic;
    font-size: 20px;
    color: $color-5;
  }

  .custom-file-upload {
    font-size: 35px;
    border: 1px solid #ccc;
    background-color: $color-2;
    border-radius: 15px;
    display: inline-block;
    padding: 0px 12px;
    margin-top: 5px;
    color: $color-4;
    cursor: pointer;
    input[type="file"] {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .button-remove {
      background-color: $color-5;
      border-radius: 35px;
      margin-left: 5px;
      color: $color-1;
      font-weight: 700;
      &:hover {
        background-color: darken($color-5, 20);
      }
    }
  }

  .button-submit {
    background-color: $color-1;
    border-radius: 35px;
    margin-top: 10px;
    color: $color-3;
  }
}

.formFieldErrorMessage {
  color: crimson;
  border-bottom: none;
}

@media (max-width: 1050px) {
  .form-container {
    width: 80%;
    .contact-form {
      width: 80%;
    }
  }
}

@media (max-width: 600px) {
  .form-container {
    width: 100%;
    .contact-form {
      width: 90%;
    }
  }
}

.select-option {
  display: block;
  font-size: 20px;
  .option-select {
    font-size: 20px;
  }
}
