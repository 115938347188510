footer {
  position: relative;
  top: 30px;
  background: $color-2;
  height: auto;
  color: $color-4;
}

.contact-mail {
  font-size: 20px;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  h3 {
    font-size: 2.5rem;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1rem;
  }
  img {
    width: 60px;
    height: 50px;
  }

  p {
    max-width: 500px;
    margin: 10px auto;
    line-height: 28px;
    font-size: 1.5rem;
    color: $color-4;
  }

  .footer-grid {
    display: flex;
    justify-content: space-around;
    gap: 36px;
    .footer-contact {
      a {
        color: $color-3;
        &:hover {
          color: $color-5;
        }
      }
    }
  }

  .socials {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 1rem 0;

    a {
      text-decoration: none;
      color: $color-3;
      margin: 5px;
      padding: 5px;
      border-radius: 50%;

      i {
        font-size: 1.5rem;
        width: 40px;
        transition: color 0.4s ease;
      }
    }
  }
}

.socials a:hover i {
  color: $color-1;
}

.footer-menu {
  background-color: $color-1;
  ul {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
  li {
    padding-right: 10px;
    display: block;
  }
  a {
    color: $color-4;
    text-decoration: none;

    &:hover {
      color: $color-3;
    }
  }
}

@media (max-width: 510px) {
  .footer-menu ul {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    li {
      a {
        font-size: 20px;
      }
    }
  }
}
