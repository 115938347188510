.picture-accompagnement {
  width: 500px;
  margin: auto;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 20px;
  }
}

.presentation-text-accompagnement {
  padding: 8px;
  margin-bottom: 16px;
  width: 80%;
  margin: auto;
  margin-top: 15px;
  text-align: center;
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  font-size: calc(22px + 1.2vw);
  strong {
    color: darken($color-2, 10);
  }

  ul {
    margin-left: 16px;

    li {
      text-align: justify;
      text-justify: distribute;
      text-align-last: left;
      font-size: calc(20px + 1.2vw);
      line-height: 1.3;
      margin-bottom: 8px;
      strong {
        color: darken($color-2, 10);
      }
    }
    li:before {
      content: "\26AB";
      font-size: 14px;
      margin-right: 8px;
    }
  }
}

.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 16px;
  .card-content {
    flex: 1 0 50%;
    border: 1px solid $color-5;
    border-radius: 15px;
    background-color: white;
    margin: 16px;
    overflow: hidden;
    flex: 1 0 25%;
    min-width: 360px;
    max-width: 550px;
    height: max-content;
    padding: 26px 15px 30px;
    position: relative;
    transition: transform 0.2s ease-out;

    h3 {
      font-size: calc(22px + 1.1vw);
      text-align: center;
      height: 64px;
    }

    img {
      width: 80%;
      height: 254px;
      object-fit: cover;
      object-position: top;
      display: block;
      margin: 0 auto;
      border-radius: 50px;
      box-shadow: 0 8px 1px rgba(255, 255, 255, 0.1),
        0 16px 1px rgba(255, 255, 255, 0.05);
    }
    &:hover {
      transform: scale(1.02);
      box-shadow: 10px 5px 5px $color-2;
    }
    .card-description {
      border: 1px solid $color-5;
      border-radius: 15px;
      padding: 8px;
      height: fit-content;
      font-size: 25px;
      overflow: hidden;
      text-align: justify;
      .open-article {
        position: relative;
        color: darken($color: $color-1, $amount: 10);
        right: 10px;
      }
    }

    .card-date {
      text-align: right;
      font-style: italic;
      font-size: calc(16px + 1.1vw);
    }

    ul {
      li {
        text-align: center;
      }
    }
    .card-button {
      background-color: $color-2;
      color: $color-4;
      span {
        font-size: 20px;
      }
      &:hover {
        background-color: darken($color: $color-2, $amount: 10);
      }
    }
  }
}

.container-radio {
  display: flex;
  flex-direction: row;
  ul {
    border-radius: 15px;
    display: flex;
    justify-content: center;
    width: 25%;
    padding: 5px;
    margin: 0 auto;
    background: $color-5;
    user-select: none;

    li {
      color: $color-3;
      font-size: 1.1rem;
      text-transform: uppercase;
      padding: 0 1rem;

      label {
        font-size: 30px;
        color: $color-4;
        cursor: pointer;
        &:hover {
          color: $color-3;
        }
      }

      input[type="radio"] {
        display: block;
        position: relative;
        &:checked + label {
          color: white;
          border-bottom: 1px solid white;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .container-radio {
    ul {
      li {
        label {
          font-size: 25px;
        }
      }
    }
  }
}

@media (max-width: 1048px) {
  .container-radio {
    ul {
      width: 25%;
    }
  }
}

@media (max-width: 890px) {
  .container-radio {
    ul {
      width: 30%;
    }
  }
}

@media (max-width: 780px) {
  .container-radio {
    ul {
      width: 35%;
    }
  }
}

@media (max-width: 620px) {
  .container-radio {
    ul {
      width: 40%;
      li {
        label {
          font-size: 25px;
        }
      }
    }
  }
  .picture-accompagnement {
    width: 350px;
    margin: auto;
  }

  .card-container {
    .card-content {
      margin-top: 0px;
      min-width: 360px;
      max-width: 450px;
      .card-description {
        height: 280px;
      }
      h3 {
        font-size: calc(20px + 1.1vw);
        text-align: center;
        height: 36px;
      }
    }
  }
}

@media (max-width: 550px) {
  .container-radio {
    ul {
      width: 50%;
      li {
        label {
          font-size: 20px;
        }
      }
    }
  }
  .card-container {
    .card-content {
      .card-description {
        height: 290px;
      }
    }
  }
}

@media (max-width: 430px) {
  .container-radio {
    ul {
      width: 60%;
    }
  }

  .picture-accompagnement {
    width: 220px;
    margin: auto;
  }

  .card-container {
    .card-content {
      .card-description {
        height: 300px;
      }
    }
  }
}

// Accompagnement Detail
.content-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    width: 100%;
    max-width: 350px;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 50px;
    box-shadow: 0 8px 1px rgba(255, 255, 255, 0.1),
      0 16px 1px rgba(255, 255, 255, 0.05);
    margin-bottom: 16px;
  }

  .detail-description {
    width: 90%;
    margin: auto;
    text-align: justify;
    text-justify: distribute;
    text-align-last: left;
    font-size: calc(20px + 1.2vw);
    line-height: 1.3;
    margin-top: 25px;
    background-color: $color-2;
    box-shadow: 5px 5px 5px $color-1;
    border-radius: 10px;
    padding: 10px;
  }

  .detail-duree,
  .detail-price {
    span {
      color: $color-3;
      text-decoration: underline;
    }
  }

  .detail-date {
    text-align: right;
    margin-right: 20px;
    font-style: italic;
  }

  .detail-button {
    width: max-content;
    margin: auto;
    margin-bottom: 10px;
    background-color: $color-3;

    span {
      font-size: 15px;
    }
    &:hover {
      background-color: $color-2;
    }
  }

  .download-pdf {
    color: darken($color: $color-1, $amount: 20);
  }
}

.phone-number-span {
  color: $color-4;
}

.description-italic {
  width: 66%;
  margin: auto;
  padding-left: 24px;
  padding-right: 24px;
  font-size: calc(16px + 1vw);
}
