.header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-1;

  .img-logo {
    width: 120px;
    height: 100px;
  }
  .img-header-container {
    width: 120px;
    .img-bandeau {
      width: 100%;
      height: auto;
      margin-left: 24px;
      clip-path: ellipse(66% 66%);
    }
  }

  h1 {
    font-family: $font-2;
    color: $color-3;
    font-size: 40px;
    p {
      color: $color-3;
      line-height: 1.2em;
      font-size: 40px;
      font-family: $font-1;
    }
  }
}

.divider-header {
  border: 4px solid white;
}

.header-social-network {
  background-color: $color-1;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 20px;
  a {
    &:hover {
      transform: scale(1.2);
    }
  }
  i {
    font-size: 25px;
    color: $color-5;
    &:hover {
      color: $color-3;
      transition: color 0.3s ease;
    }
  }
}

.header-container {
  .bubble-div {
    height: 30px;
    width: 30px;
    border: 2px solid rgba($color-3, 0.7);
    border-radius: 50px;
    position: absolute;
    top: 10%;
    left: 10%;
    animation: 4s linear infinite;
  }
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 20%;
    right: 20%;
  }
}

.header-container .bubble-div:nth-child(1) {
  top: 40%;
  left: 25%;
  animation: animate 1.5s linear infinite;
}

.header-container .bubble-div:nth-child(2) {
  top: 23%;
  left: 80%;
  animation: animate 1s linear infinite;
}

.header-container .bubble-div:nth-child(3) {
  top: 31%;
  left: 40%;
  animation: animate 1.7s linear infinite;
}

.header-container .bubble-div:nth-child(4) {
  top: 26%;
  left: 30%;
  animation: animate 2s linear infinite;
}

.header-container .bubble-div:nth-child(5) {
  top: 15%;
  left: 10%;
  animation: animate 1.3s linear infinite;
}

.header-container .bubble-div:nth-child(6) {
  top: 14%;
  left: 60%;
  animation: animate 1.5s linear infinite;
}

.header-container .bubble-div:nth-child(7) {
  top: 9%;
  left: 20%;
  animation: animate 1.5s linear infinite;
}

.header-container .bubble-div:nth-child(8) {
  top: 36%;
  left: 67%;
  animation: animate 1.9s linear infinite;
}

.header-container .bubble-div:nth-child(9) {
  top: 26%;
  left: 77%;
  animation: animate 1.8s linear infinite;
}

.header-container .bubble-div:nth-child(10) {
  top: 13%;
  left: 50%;
  animation: animate 1.4s linear infinite;
}

.header-container .bubble-div:nth-child(11) {
  top: 17%;
  left: 45%;
  animation: animate 1.8s linear infinite;
}

.header-container .bubble-div:nth-child(12) {
  top: 11%;
  left: 70%;
  animation: animate 1.5s linear infinite;
}

.header-container .bubble-div:nth-child(13) {
  top: 22%;
  left: 20%;
  animation: animate 1.7s linear infinite;
}

.header-container .bubble-div:nth-child(14) {
  top: 34%;
  left: 15%;
  animation: animate 1s linear infinite;
}

.header-container .bubble-div:nth-child(15) {
  top: 18%;
  left: 55%;
  animation: animate 1.8s linear infinite;
}

@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(70deg);
  }
  100% {
    transform: scale(1) translateY(-100px) rotate(360deg);
  }
}

@media (max-width: 720px) {
  .header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-1;

    .img-logo {
      width: 90px;
      height: 80px;
    }
    .img-bandeau {
      margin-left: 8px;
      margin-right: 4px;
      width: 88px;
      height: 88px;
    }
    h1 {
      font-size: 28px;

      p {
        line-height: 1.2em;
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 500px) {
  .header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-1;
    height: 190px;
    padding-top: 28px;
    .img-logo {
      width: 80px;
      height: 70px;
    }
    .img-bandeau {
      margin-left: 8px;
      margin-right: 4px;
      width: 60px;
      height: 60px;
    }
    h1 {
      font-size: 24px;
      width: 230px;
      p {
        line-height: 1.5em;
        font-size: 20px;
      }
    }
  }
}
