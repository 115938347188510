.sign-btns {
  display: flex;
  justify-content: flex-end;
  button {
    font-family: $font-2;
    font-size: 16px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    padding: 10px 5px;
  }
  .btn-signin {
    background-color: $color-5;
    border-radius: 15px;
    &:hover {
      background-color: $color-1;
    }
  }
}
.btn-send {
  border-radius: 15px;
  background-color: $color-2;
  &:hover {
    background-color: lighten($color: $color-2, $amount: 20);
  }
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  .btn-update {
    background-color: $color-3;
    color: $color-1;
    border-radius: 35px;
    margin-bottom: 10px;
    font-weight: 600;
    &:hover {
      background-color: darken($color-3, 20);
    }
  }

  .btn-delete {
    background-color: crimson;
    border-radius: 35px;
    margin-bottom: 10px;
    &:hover {
      background-color: darken(crimson, 20);
    }
  }
}

.container-new {
  display: flex;
  justify-content: center;
  margin: 10px;
  background-color: white;
  .btn-new {
    background-color: $color-2;
    border-radius: 35px;
  }
}

.button-back {
  background-color: $color-2;
  color: black;
  border-radius: 35px;
  margin-bottom: 10px;
  margin-top: 10px;
  &:hover {
    background-color: lighten($color-2, 20);
  }
}

.button-admin {
  background-color: $color-2;
  border-radius: 35px;
  font-size: 20px;

  &:hover {
    background-color: darken($color: $color-1, $amount: 10);
    color: $color-4;
    box-shadow: 5px 5px 5px $color-2;
  }
}

.button-not-available {
  background-color: crimson;
  border-radius: 35px;
  &:visited {
    text-decoration: none;
  }
  &:focus {
    background-color: crimson;
  }
  &:hover {
    background-color: darkred;
  }
}

.button-available {
  background-color: green;
  border-radius: 35px;
  &:visited {
    text-decoration: none;
  }
  &:focus {
    background-color: green;
  }
  &:hover {
    background-color: darkgreen;
  }
}

.btn-sign-access {
  background-color: $color-1;
  border-radius: 15px;
  font-size: 25px;
  &:hover {
    background-color: $color-5;
    color: $color-3;
  }
}
