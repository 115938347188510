@font-face {
  font-family: "font-1";
  src: url(../assets/fonts/Dongle-Regular.ttf);
}
@font-face {
  font-family: "font-2";
  src: url(../assets/fonts/Pacifico-Regular.ttf);
}

$font-1: "font-1", sans-serif;
$font-2: "font-2", sans-serif;

$color-1: #1e3d59;
$color-2: #829079;
$color-3: #f5f0e1;

$color-5: #ffc13b;
$color-4: white;

// $color-1: #8fbdd9;
// $color-2: #fbe0c3;
// $color-3: #7d8e95;
// $color-4: #344648;
// $color-5: #ffbb98;

$lighten-1: lighten(
  $color: $color-1,
  $amount: 15,
);
$lighten-2: lighten(
  $color: $color-1,
  $amount: 20,
);
$lighten-3: lighten(
  $color: $color-5,
  $amount: 5,
);

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: white;
  font-family: $font-1;
  font-size: 28px;
  width: 100%;
}

@media (min-width: 1500px) {
  body {
    margin: auto;
    background-color: none;
  }
}
