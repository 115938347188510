.container-home {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  .home-description {
    .home-paragraphe {
      border-radius: 35px;
      padding-left: 8px;
      padding-right: 8px;
      width: 95%;
      margin: auto;
      background-color: white;
      box-shadow: 5px 5px 5px $color-1;
      border-radius: 10px;
    }
    .home-paragraphe-citation {
      border-radius: 35px;
      padding-left: 8px;
      padding-right: 8px;
      width: 95%;
      margin: auto;
      .citation {
        text-align: center;
        font-size: calc(36px + 1.3vw);
        font-weight: 400;
        font-style: italic;
        q {
          font-weight: 500;
          font-size: calc(48px + 1.3vw);
          font-style: normal;
        }
      }
    }
  }
}
.img-home {
  margin: auto;
  margin-top: 16px;
  width: 33%;
  height: 33%;
  object-fit: cover;
  border-radius: 35px;
}

.img-qrcode {
  margin-top: 25px;
  width: 20%;
  height: 20%;
}

.qr-btn {
  margin-top: 25px;
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;

  padding: 10px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

@media (max-width: 630px) {
  .img-home {
    width: 66%;
  }
}

.presentation-text-home {
  padding: 16px;
  margin-bottom: 8px;
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  font-size: calc(24px + 1.2vw);
  line-height: 1.3;
}

.link-rdv {
  align-items: center;
  background-color: $color-2;
  border: 2px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: #111;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.link-rdv:after {
  background-color: #111;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform 0.2s ease-out;
  z-index: -1;
}

.link-rdv:hover:after {
  transform: translate(0, 0);
}

.link-rdv:active {
  background-color: #ffdeda;
  outline: 0;
}

.link-rdv:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .link-rdv {
    padding: 0 40px;
  }
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
}

.socials-contact {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1rem 0;

  a {
    text-decoration: none;
    color: $color-2;
    margin: 5px;
    padding: 5px;
    border-radius: 50%;

    i {
      font-size: 1.5rem;
      width: 40px;
      transition: color 0.4s ease;
    }
  }
}

.photo-contact {
  margin: auto;
  margin-top: 16px;
  width: 33%;
  height: 33%;
  object-fit: cover;
  border-radius: 35px;
}

@media (max-width: 630px) {
  .photo-contact {
    width: 66%;
  }
}
