.container-presentation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 95%;
  margin: auto;

  .container-present-image {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 5px $color-1;
    border-radius: 15px;
    padding: 10px;
    background-color: $color-2;
    img {
      width: 100%;
      max-width: 200px;
      min-width: 160px;
      height: auto;
      border-radius: 10px;
      margin-right: 16px;
    }
    p {
      text-align: justify;
      text-justify: distribute;
      text-align-last: left;
      font-size: calc(24px + 1.2vw);
      line-height: 1.3;
    }
  }
}

.presentation-text {
  background-color: white;
  box-shadow: 5px 5px 5px $color-1;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 8px;
  text-align: justify;
  text-justify: distribute;
  text-align-last: left;
  font-size: calc(24px + 1.2vw);
  line-height: 1.3;
}

.presentation-middleTitle {
  background-color: $color-2;
  box-shadow: 5px 5px 5px $color-1;
  border-radius: 10px;
  width: 95%;
  margin-bottom: 8px;
  margin-top: 16px;
  padding: 8px;
  text-align: center;
  font-size: calc(28px + 1.4vw);
  line-height: 1.3;
}

.presentation-citation {
  background-color: $color-2;
  box-shadow: 5px 5px 5px $color-1;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 25px;
  text-align: center;
  font-size: calc(26px + 1.4vw);
  strong {
    color: white;
  }
}

@media (max-width: 620px) {
  .container-presentation {
    .container-present-image {
      flex-direction: column;
      img {
        width: 100%;
        max-width: 250px;
        min-width: 160px;
        height: auto;
        border-radius: 10px;
        margin-right: 16px;
      }
    }
  }
}

strong {
  color: $color-1;
}
