.containerSlide {
  width: 600px;
  margin: auto;
  padding-top: 40px;
  .each-slide {
    height: 325px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 800px) {
  .containerSlide {
    width: 500px;
    .each-slide {
      height: 250px;
    }
  }
}

@media (max-width: 580px) {
  .containerSlide {
    width: 400px;
    .each-slide {
      height: 220px;
    }
  }
}

@media (max-width: 420px) {
  .containerSlide {
    width: 300px;
    .each-slide {
      height: 150px;
    }
  }
}
