.sub-title {
  text-align: center;
  font-family: $font-2;
  color: $color-1;
}

.testimonial-parent > div:nth-child(even) {
  background-color: lighten($color-2, 10);
}

.testimonial-container {
  background-color: $color-3;
  margin: auto;
  border-radius: 35px;
  width: 90%;
  box-shadow: 10px 5px 5px $color-3;

  h3 {
    text-align: center;
    padding: 8px;
  }

  img {
    width: 60%;
    height: 254px;
    object-fit: cover;
    object-position: top;
    display: block;
    margin: 0 auto;
    border-radius: 50px;
    box-shadow: 0 8px 1px rgba(255, 255, 255, 0.1),
      0 16px 1px rgba(255, 255, 255, 0.05);
  }

  .testimonial-description {
    padding: 12px;
    text-align: justify;
    text-justify: distribute;
    text-align-last: left;
    font-size: calc(24px + 1.2vw);
    line-height: 1.3;
  }

  .testimonial-created {
    text-align: right;
    margin-right: 20px;
    font-style: italic;
    padding: 4px;
    font-size: calc(20px + 1.2vw);
  }
}

@media (max-width: 630px) {
  .testimonial-container {
    img {
      width: 75%;
    }
  }
}

@media (max-width: 465px) {
  .testimonial-container {
    img {
      width: 95%;
    }
  }
}
